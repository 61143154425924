// Auth Routes (Login required)
import React from "react";
import PricingDetails from "../containers/pricing-details";
import IframeRedirect from "../containers/partner-details-form/iframeRedirect";
import ThankYouPartnerDetails from "../containers/partner-details-form/thank-you";

// Public Routes
const NotFoundPage = React.lazy(() => import("../containers/state-page/not-found"));
const PricingPayment = React.lazy(() => import("../containers/pricing-details/payment"));
const Advisor = React.lazy(() => import("../containers/advisor"));
const AdvisorSuggestion = React.lazy(() => import("../containers/advisor/suggestion/table/AdviosorSuggestionTable"));
const AdvisorForms = React.lazy(() => import("../containers/advisor/forms"));

const Login = React.lazy(() => import("../containers/account/login/index"));
const RegisterMember = React.lazy(() => import("../containers/account/member"));
const SignUP = React.lazy(() => import("../containers/account/sign-up/index"));
const PartnerDetailsForm = React.lazy(() => import("../containers/partner-details-form/index"));
const RecoverPassword = React.lazy(() => import("../containers/account/forgot"));
const ResetPassword = React.lazy(() => import("../containers/account/resetPassword"));
const Client = React.lazy(() => import("../containers/clients/index"));
const AddClient = React.lazy(() => import("../containers/clients/addClient"));
const ClientDetails = React.lazy(() => import("../containers/clients/details"));
const Details = React.lazy(() => import("../containers/Details"));
const EditUser = React.lazy(() => import("../containers/settings/components/EditUser"));
const AddUser = React.lazy(() => import("../containers/settings/components/AddUser"));
const Settings = React.lazy(() => import("../containers/settings"));
const SinistroForm = React.lazy(() => import("../containers/paperForm/Sinistros"));
const Rebicos = React.lazy(() => import("../containers/rebicos"));
const RebicosDetails = React.lazy(() => import("../containers/rebicos/details"));
const ColorSettings = React.lazy(() => import("../containers/settings/color"));
const Signature = React.lazy(() => import("../containers/partner-details-form/signature"));
const TravelJourney = React.lazy(() => import("../containers/journey/travel-v1"));
const TravelPackages = React.lazy(() => import("../containers/results/travel"));
const TravelSummary = React.lazy(() => import("../containers/summary/travel"));
const ThankYouTravel = React.lazy(() =>import("../containers/thank-you/travel"));
const CarJourney = React.lazy(() => import("../containers/journey/car"));
const CarResults = React.lazy(() => import("../containers/results/car/index"));
const CarAuto = React.lazy(() => import("../containers/results/auto"));
const CarSummary = React.lazy(() => import("../containers/summary/car"));
const ThankYouCar = React.lazy(() => import("../containers/thank-you/car"));

const WorkJourney = React.lazy(() => import("../containers/journey/workAccident"));
const WorkResults = React.lazy(() => import("../containers/results/workAccident/results"));
const WorkSummary = React.lazy(() => import("../containers/summary/workAccident"));
const ThankYouWork = React.lazy(() => import("../containers/thank-you/workAccident"));

const WorkAccidentOtherJourney = React.lazy(() => import("../containers/journey/workOther"));
const WorkAccidentOtherResults = React.lazy(() => import("../containers/results/workOther"));
const WorkAccidentOtherSummary = React.lazy(() => import("../containers/summary/workOther"));
const WorkAccidentOtherThankYou = React.lazy(() => import("../containers/thank-you/workOther"));
const WorkOtherDetails = React.lazy(() => import("../containers/policies/policy-details-v1/work-other"));


const HouseKeeperJourney = React.lazy(() => import('../containers/journey/housekeeper/HouseKeeperJourney'));
const HouseKeeperSummary = React.lazy(() => import('../containers/summary/housekeeper/HouseKeeperSummary'));
const HouseKeeperResults = React.lazy(() => import('../containers/results/housekeeper/results'));
const HousekeeperDetails = React.lazy(() => import("../containers/policies/policy-details-v1/housekeeper"));
const ThankYouHouseKeeper = React.lazy(() => import('../containers/thank-you/housekeeper'));


const HealthJourney = React.lazy(() => import("../containers/journey/health"));
const HealtResults = React.lazy(() => import("../containers/results/health/index"));
const HealthCompare = React.lazy(() => import("../containers/compare_new/compare-health-new"));
const HealthSummary = React.lazy(() => import("../containers/summary/health"));
const ThankYouHealth = React.lazy(() => import("../containers/thank-you/health"));
const TravelCompare = React.lazy(() => import("../containers/compare_new/compare-travel-new-v1"));
const Quotes = React.lazy(() => import("../containers/quotes/index"));
const Policies = React.lazy(() => import("../containers/policies"));
const TravelDetails = React.lazy(() => import("../containers/policies/policy-details-v1/travel"));
const MortgageDetails = React.lazy(() =>import("../containers/policies/policy-details-v1/mortgage"));
const CompareCar = React.lazy(() => import("../containers/compare_new/compare-car-new"));
const CompareBike = React.lazy(() => import("../containers/compare_new/compare-bike-new/index"));
const CompareAuto = React.lazy(() => import("../containers/compare_new/compare-auto-new"));
const CompareMortgage = React.lazy(() => import("../containers/compare_new/compare-mortgage-new"));
const CarDetails = React.lazy(() => import("../containers/policies/policy-details-v1/car"));
const CarDetailsCompany = React.lazy(() => import("../containers/policies/policy-details-v1/car-company"));

const BikeDetails = React.lazy(() => import("../containers/policies/policy-details-v1/bike"));
const HomeDetails = React.lazy(() => import("../containers/policies/policy-details/home"));
const HealthDetails = React.lazy(() => import("../containers/policies/policy-details/health"));
const WorkAccidentDetails = React.lazy(() => import("../containers/policies/policy-details/workAccident"));
const OtherDetails = React.lazy(() => import("../containers/policies/policy-details/other"));
const ComprarJourney = React.lazy(() => import("../containers/comprar-journeys"));
const OpenAdvisor = React.lazy(() => import("../containers/advisor/openAdvisors"));

const MortgageJourney = React.lazy(() => import("../containers/journey/mortgage"));
const MortgageResults = React.lazy(() => import("../containers/results/mortgage"));
const MortgageSummary = React.lazy(() => import("../containers/summary/mortgage-v2/MortgageSummary"));
const ThankYouMortgage = React.lazy(() => import("../containers/thank-you/mortgage"));
const CarCompany = React.lazy(() => import("../containers/journey/car-company"));

const CarCompanySummary = React.lazy(() => import("../containers/summary/car-company"));
const ThankYouCarCompany = React.lazy(() => import("../containers/thank-you/car-company"));
const WarrantyDetails=React.lazy(()=>import('../containers/policies/policy-details-v1/warranty'))

const BikeJourney = React.lazy(() => import("../containers/journey/bike"));
const BikeResult = React.lazy(() => import("../containers/results/bike"));
const BikeSummary = React.lazy(() => import("../containers/summary/bike"));
const BikeThankYou = React.lazy(() => import("../containers/thank-you/bike"));

const Dashboard = React.lazy(() => import("../containers/dashboard/index"));

// const Landing = React.lazy(() => import("../containers/account/landing"));

const routes = [
  {
    path: "/not-found",
    exact: true,
    name: "NotFoundPage",
    component: NotFoundPage,
    authRequired: false,
  },

  {
    path: "/entrar",
    exact: true,
    name: "Entrar",
    component: Login,
    authRequired: false,
    showHeader: false,
  },

  {
    path: "/register-contact",
    exact: true,
    name: "RegisterMember",
    component: RegisterMember,
    authRequired: false,
    showHeader: false,
  },
  {
    path: "/registar",
    exact: true,
    name: "Registar",
    component: SignUP,
    authRequired: false,
    showHeader: false,
  },
  {
    path: "/recuperar-pass",
    exact: true,
    name: "Registar",
    component: RecoverPassword,
    authRequired: false,
    showHeader: false,
  },
  {
    path: "/restore",
    exact: true,
    name: "Restore",
    component: ResetPassword,
    authRequired: false,
    showHeader: false,
  },
  {
    path: "/formulario-adesao",
    exact: true,
    name: "PartnerDetailsForm",
    component: PartnerDetailsForm,
    authRequired: true,
  },
  {
    path: "/dados",
    exact: true,
    name: "Details",
    component: Details,
    authRequired: true,
  },
  {
    path: "/dashboard",
    exact: true,
    name: "Deashboard",
    component: Dashboard,
    authRequired: true,
  },
  {
    path: "/recibos",
    exact: true,
    name: "Rebicos",
    component: Rebicos,
    authRequired: true,
  },
  {
    path: "/recibos-detalhes",
    exact: true,
    name: "RebicosDetails",
    component: RebicosDetails,
    authRequired: true,
  },
  {
    path: "/formulario-assinatura",
    exact: true,
    name: "Signature",
    component: Signature,
    authRequired: true,
  },
  {
    path: "/formulário-plano",
    exact: true,
    name: "PricingDetails",
    component: PricingDetails,
    authRequired: true,
  },
  {
    path: "/formulário-payment",
    exact: true,
    name: "PricingPayment",
    component: PricingPayment,
    authRequired: true,
  },
  {
    path: "/iframe-redirect-success",
    exact: true,
    name: "IframeRedirectSuccess",
    component: IframeRedirect,
    authRequired: false,
  },
  {
    path: "/formulario-submetido",
    exact: true,
    name: "ThankYouPagePartnerDetails",
    component: ThankYouPartnerDetails,
    authRequired: true,
  },
  {
    path: "/configuracoes",
    exact: true,
    name: "Settings",
    component: Settings,
    authRequired: true,
  },
  {
    path: "/configuracoes-marca",
    exact: true,
    name: "ColorSettings",
    component: ColorSettings,
    authRequired: true,
  },
  {
    path: "/editar-membro",
    exact: true,
    name: "EditUser",
    component: EditUser,
    authRequired: true,
  },
  {
    path: "/add-user",
    exact: true,
    name: "AddUser",
    component: AddUser,
    authRequired: true,
  },
  {
    path: "/simulações",
    exact: true,
    name: "simulações",
    component: Quotes,
    authRequired: true,
  },
  {
    path: "/seguros",
    exact: true,
    name: "Policies",
    component: Policies,
    authRequired: true,
  },
  {
    path: "/seguro-viagem/simulacao/:journeyId?",
    exact: true,
    name: "TravelJourney",
    component: TravelJourney,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-viagem/resultados/pacotes/:journeyId?",
    exact: true,
    name: "TravelPackages",
    component: TravelPackages,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-viagem/resultados/summary/:orderId?",
    exact: true,
    name: "TravelSummary",
    component: TravelSummary,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-viagem/compra/obrigado/:orderId",
    exact: true,
    name: "ThankYou",
    component: ThankYouTravel,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-viagem-detalhes/:orderId?",
    exact: true,
    name: "TravelDetails",
    component: TravelDetails,
    authRequired: true,
  },
  {
    path: "/seguro-vida-credito-detalhes/:orderId?",
    exact: true,
    name: "MortgageDetails",
    component: MortgageDetails,
    authRequired: true,
  },
  {
    path: "/seguro-carro-detalhes",
    exact: true,
    name: "CarDetails",
    component: CarDetails,
    authRequired: true,
  },
  {
    path: "/seguro-carro-empresas-detalhes",
    exact: true,
    name: "CarDetailsCompany",
    component: CarDetailsCompany,
    authRequired: true,
  },
  {
    path: "/seguro-acidentes-trabalho-outrem-detalhes",
    exact: true,
    name: "WorkOtherDetails",
    component: WorkOtherDetails,
    authRequired: true,
  },
  {
    path: "/seguro-moto-detalhes",
    exact: true,
    name: "BikeDetails",
    component: BikeDetails,
    authRequired: true,
  },
  {
    path: "/seguro-casa-detalhes",
    exact: true,
    name: "HomeDetails",
    component: HomeDetails,
    authRequired: true,
  },
  {
    path: "/seguro-saúde-detalhes",
    exact: true,
    name: "HealthDetails",
    component: HealthDetails,
    authRequired: true,
  },
  {
    path: "/seguro-acidentes-trabalho-independente-detalhes",
    exact: true,
    name: "WorkAccidentDetails",
    component: WorkAccidentDetails,
    authRequired: true,
  },
  {
    path: "/seguro-empregada-detalhes",
    exact: true,
    name: "HousekeeperDetails",
    component: HousekeeperDetails,
    authRequired: true,
  },
  {
    path: "/seguro-outro-detalhes",
    exact: true,
    name: "OtherDetails",
    component: OtherDetails,
    authRequired: true,
  },
  {
    path: "/cotar-seguros",
    exact: true,
    name: "ComprarJourney",
    component: ComprarJourney,
    authRequired: true,
  },
  {
    path: "/seguro-viagem/resultados/comparar/:journeyId?",
    exact: true,
    name: "TravelCompare",
    component: TravelCompare,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  // { path: '/consultor', exact: true, name: 'Advisor', component: Advisor, authRequired: false },
  {
    path: "/venda-cruzada/resultados",
    exact: true,
    name: "AdvisorSuggestion",
    component: AdvisorSuggestion,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/venda-cruzada/questionario/:formId?",
    exact: true,
    name: "AdvisorForms",
    component: AdvisorForms,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
    customFooter: true,
  },
  {
    path: "/venda-cruzada",
    exact: true,
    name: "OpenAdvisor",
    component: OpenAdvisor,
    authRequired: true,
  },
  {
    path: "/clientes",
    exact: true,
    name: "Client",
    component: Client,
    authRequired: true,
  },
  {
    path: "/adicionar-cliente",
    exact: true,
    name: "AddClient",
    component: AddClient,
    authRequired: true,
  },
  {
    path: "/clientes-detalhes",
    exact: true,
    name: "ClientDetails",
    component: ClientDetails,
    authRequired: true,
  },
  {
    path: "/participacao-sinistro",
    exact: true,
    name: "SinistroForm",
    component: SinistroForm,
    authRequired: true,
  },
  {
    path: "/seguro-vida-credito/simulacao/:journeyId?",
    exact: true,
    name: "MortgageJourney",
    component: MortgageJourney,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-vida-credito/resultados/pacotes/:journeyId?",
    exact: true,
    name: "MortgageResults",
    component: MortgageResults,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-vida-credito/resultados/comparar/:journeyId?",
    exact: true,
    name: "CompareMortgage",
    component: CompareMortgage,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-vida-credito/resultados/summary/:orderId?",
    exact: true,
    name: "MortgageSummary",
    component: MortgageSummary,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-vida-credito/compra/obrigado/:orderId?",
    exact: true,
    name: "ThankYouMortgage",
    component: ThankYouMortgage,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-carro/simulacao",
    exact: true,
    name: "CarJourney",
    component: CarJourney,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-carro/resultados/pacotes/:journeyId?",
    exact: true,
    name: "CarResults",
    component: CarResults,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-carro-empresas/resultados/pacotes/:journeyId?",
    exact: true,
    name: "CarAuto",
    component: CarAuto,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-carro/resultados/comparar/:journeyId?",
    exact: true,
    name: "CompareCar",
    component: CompareCar,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-moto/resultados/comparar/:journeyId?",
    exact: true,
    name: "CompareBike",
    component: CompareBike,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-carro-empresas/resultados/comparar/:journeyId?",
    exact: true,
    name: "CompareAuto",
    component: CompareAuto,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-carro/resultados/summary/:orderId?",
    exact: true,
    name: "CarSummary",
    component: CarSummary,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-carro/compra/obrigado/:orderId?",
    exact: true,
    name: "ThankYouCar",
    component: ThankYouCar,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },

  {
    path: "/seguro-saude/simulacao",
    exact: true,
    name: "HealthJourney",
    component: HealthJourney,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-saude/resultados/pacotes/:journeyId?",
    exact: true,
    name: "HealtResults",
    component: HealtResults,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-saude/resultados/comparar/:journeyId?",
    exact: true,
    name: "HealthCompare",
    component: HealthCompare,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-saude/resultados/summary/:orderId?",
    exact: true,
    name: "HealthSummary",
    component: HealthSummary,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-saude/compra/obrigado/:orderId?",
    exact: true,
    name: "ThankYouHealth",
    component: ThankYouHealth,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-acidentes-trabalho-independente/simulacao",
    exact: true,
    name: "WorkJourney",
    component: WorkJourney,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path:
      "/seguro-acidentes-trabalho-independente/resultados/pacotes/:journeyId?",
    exact: true,
    name: "WorkResults",
    component: WorkResults,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path:
      "/seguro-acidentes-trabalho-independente/resultados/summary/:orderId?",
    exact: true,
    name: "WorkSummary",
    component: WorkSummary,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-acidentes-trabalho-independente/compra/obrigado/:orderId?",
    exact: true,
    name: "ThankYouWork",
    component: ThankYouWork,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-empregada/simulacao",
    exact: true,
    name: "HouseKeeperJourney",
    component: HouseKeeperJourney,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path:"/seguro-empregada/resultados/pacotes/:journeyId?",
    exact: true,
    name: "HouseKeeperResults",
    component: HouseKeeperResults,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path:"/seguro-empregada/resultados/summary/:orderId?",
    exact: true,
    name: "HouseKeeperSummary",
    component: HouseKeeperSummary,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-empregada/compra/obrigado/:orderId?",
    exact: true,
    name: "ThankYouHouseKeeper",
    component: ThankYouHouseKeeper,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-carro-empresas/simulacao",
    exact: true,
    name: "CarCompany",
    component: CarCompany,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-carro-empresas/resultados/summary/:orderId?",
    exact: true,
    name: "CarCompanySummary",
    component: CarCompanySummary,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-carro-empresas/compra/obrigado/:orderId?",
    exact: true,
    name: "ThankYouCarCompany",
    component: ThankYouCarCompany,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-acidentes-trabalho-outrem/simulacao",
    exact: true,
    name: "WorkAccidentOtherJourney",
    component: WorkAccidentOtherJourney,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-acidentes-trabalho-outrem/resultados/pacotes/:journeyId?",
    exact: true,
    name: "WorkAccidentOtherResults",
    component: WorkAccidentOtherResults,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-acidentes-trabalho-outrem/resultados/summary/:orderId?",
    exact: true,
    name: "WorkAccidentOtherSummary",
    component: WorkAccidentOtherSummary,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-acidentes-trabalho-outrem/compra/obrigado/:orderId?",
    exact: true,
    name: "WorkAccidentOtherThankYou",
    component: WorkAccidentOtherThankYou,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-moto/simulacao",
    exact: true,
    name: "BikeJourney",
    component: BikeJourney,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-moto/resultados/pacotes/:journeyId?",
    exact: true,
    name: "BikeResult",
    component: BikeResult,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-moto/resultados/summary/:orderId?",
    exact: true,
    name: "BikeSummary",
    component: BikeSummary,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-moto/compra/obrigado/:orderId?",
    exact: true,
    name: "BikeThankYou",
    component: BikeThankYou,
    authRequired: false,
    isContainer: true,
    showHeader: true,
    customFooter: true,
  },
  {
    path: "/seguro-garantia-detalhes",
    exact: true,
    name: "WarrantyDetails",
    component: WarrantyDetails,
    authRequired: true,
  },
];

export default routes;
