export const convertPricetoFormat = (
  value: number,
  hasSymbol: any = false,
  symbol: any = null,
  maximumFractionDigits: any = 2,
  minimumFractionDigits: any = 2
) => {
  if (value != null) {
    return `${new Intl.NumberFormat('de-DE', {
      maximumFractionDigits: maximumFractionDigits,
      minimumFractionDigits: minimumFractionDigits
    }).format(Number(value))} ${hasSymbol ? '€' : ''} ${symbol ? symbol : ''}`
  }
  return null;
}
